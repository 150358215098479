configApp.controller('CustomerRegisterController', ['$scope', '$location', '$config', '$cookies','$http', '$route', '$rootScope',  function($scope, $location, $config, $cookies, $http, $route, $rootScope) {

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $config.setIndex(0);


    //Vars
    $scope.selected;
    $scope.registered = false;

    $scope.sent = false;


    $scope.countries = $config.getCountriesObject();
    $scope.favCountries = $config.getFavoriteCountries();


    $http.get($config.getServerPath() + "/api/v1/user/get").then(function(response) {
        if(response.status == 200 && response.data.status == 'loggedin') {
            $scope.input.customer = response.data.customer;
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'home/calculations');
        }  else if(response.status !== 200) {
            $rootScope.$broadcast('overlay', {status : response.status});
        }
    }, function(response) {
        $rootScope.$broadcast('overlay', {status : response.status});
    });
    $scope.continue = function() {
        if(!$scope.form.$valid) {
            $scope.sent = true;
            return;
        }
        if($location.url().slice(-1) != "2" && $location.url().slice(-1) != "3") {
            $location.path($config.getBaseUrl() + 'customer/register2');
            return;
        }
        if($location.url().slice(-1) == "2" && $scope.input.customer.password == $scope.input.customer.passwordConfirm) {
            $location.path($config.getBaseUrl() + 'customer/register3');
            return;
        }
        if($location.url().slice(-1) == "3") {
            $scope.register();
            //var data = {
            //    firstName: $scope.input.customer.firstName,
            //    lastName: $scope.input.customer.lastName,
            //    company : $scope.input.customer.company,
            //    lang : $scope.input.customer.lang,
            //    email : $scope.input.customer.email,
            //    country : $scope.input.customer.country,
            //    zip : $scope.input.customer.zip,
            //    password: $scope.input.customer.password,
            //    passwordConfirm: $scope.input.customer.passwordConfirm,
            //    systemOfMeasurement: $scope.input.systemOfMeasurement,
            //    oemEnd: $scope.input.customer.oemEnd,
            //    interest: $scope.input.customer.interest
            //};
        }
    }


    $scope.register = function() {
        if(!$scope.form.$valid){
            $scope.sent = true;
        }
        if($scope.form.$valid && $scope.password == $scope.passwordConfirm) {
            var data = {
                firstName: $scope.input.customer.firstName,
                lastName: $scope.input.customer.lastName,
                company : $scope.input.customer.company,
                lang : $scope.input.customer.lang,
                email : $scope.input.customer.email,
                country : $scope.input.customer.country,
                city : $scope.input.customer.city,
                street : $scope.input.customer.street,
                zip : $scope.input.customer.zip,
                phonenumber: $scope.input.customer.phonenumber,
                password: $scope.password,
                passwordConfirm: $scope.passwordConfirm,
                systemOfMeasurement: $scope.input.systemOfMeasurement,
                usertype: $scope.input.customer.usertype,
                interest: $scope.input.customer.interest,
                data_privacy_confirmed: $scope.input.customer.data_privacy_confirmed
            };
            $scope.loading = true;
            $http.post($config.getServerPath() + "/api/v1/user/register", data).then(function(data) {
                if(data.status == 200 && data.data.status=='success') {
                    $scope.input.customer = data.data.customer;
                    $scope.input.customer.isLoggedIn = true;
                    $scope.input.customer.preferedSOU = 'metric';
                    $config.setInput($scope.input);
                    $location.path($config.getBaseUrl() + 'home/calculations');
                }
                else {
                    $scope.msg = data.data.msg;
                    $scope.loading = false;
                }
                $scope.loading = false;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : response.status});
                $scope.loading = false;
            });
        }
    };

    $scope.back = function () {
        if($location.url().slice(-1) != "2" && $location.url().slice(-1) != "3") {
            $location.path($config.getBaseUrl() + 'customer');
            return;
        }
        if($location.url().slice(-1) == "2") {
            $location.path($config.getBaseUrl() + 'customer/register');
            return;
        }
        if($location.url().slice(-1) == "3") {
            $location.path($config.getBaseUrl() + 'customer/register2');
            return;
        }
    };

}]);
