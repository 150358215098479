configApp.controller('ApplicationController', ['$scope', '$rootScope', '$config', '$location', '$route', function($scope, $rootScope, $config, $location, $route) {

    //Vars
    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('applicationDetails')){
        $location.path($config.getBaseUrl() + 'application/case');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;
    
    //Setting default value for environmental conditions
    if($scope.input.application.environment == '' || typeof $scope.input.application.environment == undefined) {
        switch($scope.input.application.case) {
            case 'foodWashdown':
                $scope.input.application.environment = 'wet';
                break;
            case 'foodDry':
                $scope.input.application.environment = 'wet';
                break;
            case 'airport':
                $scope.input.application.environment = 'dry';
                break;
            case 'xray':
                $scope.input.application.environment = 'dry';
                break;
            case 'dryLogistic':
                $scope.input.application.environment = 'dry';
                break;
            default:
                break;
        }
    }
    
    //Setting default values for ambient temperature
    if($scope.input.application.temperature == '' || typeof $scope.input.application.temperature == undefined) {
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.application.temperature = 20;
        } else {
            $scope.input.application.temperature = 68;
        }
    }
    
    //Setting default values for altitude above sea
    if($scope.input.application.altitude == '' || typeof $scope.input.application.altitude == undefined) {
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.application.altitude = 500;
        } else {
            $scope.input.application.altitude = 1640;
        }
    }

    //functions
    $scope.submit = function() {
        if($scope.form.$valid){
            if($scope.input.systemOfMeasurement == 'metric') {
                if($scope.input.application.temperature > 25) {
                   var message = "You are running an application with a ambient temperature above 25°C. You won't be able to use a non belt application.";
                    $rootScope.$broadcast('overlay', {message: message, header: 'Warning', callback: function(){
                        if($scope.input.belt.type !== "friction") {
                            $scope.input.belt.type = "";
                            $config.setValid('beltDetails', false);
                        }
                        $location.path($config.getBaseUrl() + 'conveyor/type');
                    }});
                } else {
                    $location.path($config.getBaseUrl() + 'conveyor/type');
                }
            } else {
                if($scope.input.application.temperature > 77) {
                   var message = "You are running an application with a ambient temperature above 77°F. You won't be able to use a non belt application.";
                    $rootScope.$broadcast('overlay', {message: message, header: 'Warning', callback: function(){
                        if($scope.input.belt.type !== "friction") {
                            $scope.input.belt.type = "";
                            $config.setValid('beltDetails', false);
                        }
                        $location.path($config.getBaseUrl() + 'conveyor/type');
                    }});
                } else {
                    $location.path($config.getBaseUrl() + 'conveyor/type');
                }
            }
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'application/case');
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('conveyorType', true);
        } else {
            $config.setValid('conveyorType', false);
        }
    });
}]);
