configApp.controller('BeltTypeController', ['$scope', '$config', '$location', '$route', function($scope, $config, $location, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('beltType')) {
        $location.path($config.getBaseUrl() + 'conveyor/accumulation');
    }

    $scope.initialType = $scope.input.belt.type;
    var initialValid = {};
    angular.copy($scope.input.valid, initialValid);

    //Setting up help box correctly
    switch($scope.initialType) {
        case 'friction':
            globalRadioSelect = $('#one').parent().parent();
            break;
        case 'positiveModular':
            globalRadioSelect = $('#two').parent().parent();
            break;
        case 'positiveHomogeneous':
            globalRadioSelect = $('#three').parent().parent();
            break;
        case 'none':
            globalRadioSelect = $('#four').parent().parent();
            break;
        default:
            globalRadioSelect = null;
            break;
    }

    if($scope.input.belt.support == "slider") {
        $('#carryingRollers').addClass('unreachable');
    } else {
        $('#carryingRollers').removeClass('unreachable');
    }

    if($scope.initialType == "none") {
        $('#beltDetails').addClass('unreachable');
        $('#returnRollers').addClass('unreachable');
        $('#beltCoating').removeClass('unreachable');
    } else {
        $('#beltDetails').removeClass('unreachable');
        $('#beltSupport').removeClass('unreachable');
        $('#returnRollers').removeClass('unreachable');
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
    }

    $scope.submit = function () {
        if($scope.input.belt.type != "") {
            if($scope.input.belt.type == "none"){
                $scope.input.belt.support = 'roller';
                $scope.input.belt.frictionFactor = 0.05;
                $config.setInput($scope.input);
                $location.path($config.getBaseUrl() + 'belt/coating');
            } else {
                $config.setInput($scope.input);
                $location.path($config.getBaseUrl() + 'belt/details');
            }
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'conveyor/accumulation');
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.changed = function() {
        $scope.input.calculation.inputsChanged = true;
        if($scope.initialType != $scope.input.belt.type){
            switch ($scope.initialType) {
                case 'friction':
                    if($scope.input.belt.type == 'positiveModular') {
                        $config.setValid('beltCoating', false);
                    }
                    $config.setValid('result', false);
                    break;
                case 'positiveModular':
                    $config.setValid('beltCoating', false);
                    $config.setValid('beltSupport', false);
                    break;
                case 'positiveHomogeneous':
                    if($scope.input.belt.type == 'friction' || $scope.input.belt.type == 'positiveModular') {
                        $config.setValid('beltCoating', false);
                    }
                    break;
                case 'none':
                    $config.setValid('beltCoating', false);
                    if($scope.input.belt.type == 'friction') {
                        $config.setValid('beltSupport', false);
                    }
                    break;
            }
            if($scope.input.belt.type != '' || $scope.input.belt.type != null) {
                $config.setValid('beltDetails', true);
            }
            if($scope.input.belt.type == 'none') {
                $config.setValid('beltCoating', true);
            }
        } else {
            angular.copy(initialValid, $scope.input.valid);
            $config.setInput($scope.input);
        }
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
        if($scope.input.belt.type == "none"){
            $('#beltDetails').addClass('unreachable');
            $('#returnRollers').addClass('unreachable');
            $('#carryingRollers').removeClass('unreachable');
        } else {
            $('#beltDetails').removeClass('unreachable');
            $('#beltSupport').removeClass('unreachable');
            $('#returnRollers').removeClass('unreachable');
            if($scope.input.belt.support == "slider") {
              $('#carryingRollers').addClass('unreachable');
            } else {
              $('#carryingRollers').removeClass('unreachable');
            }
        }
    };
}]);
