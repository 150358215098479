configApp.controller('BeltCoatingController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('beltCoating')) {
        $location.path($config.getBaseUrl() + 'belt/details');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;


    if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous"){
        if($config.getValidValue('beltSupport')){
            $location.path($config.getBaseUrl() + 'belt/support');
        } else {
            $location.path($config.getBaseUrl() + 'belt/details');
        }
    }

    if($scope.input.belt.support == "slider") {
        $('#carryingRollers').addClass('unreachable');
    } else {
        $('#carryingRollers').removeClass('unreachable');
    }

    if($scope.input.belt.type == "none") {
        $('#beltDetails').addClass('unreachable');
    } else {
        $('#beltDetails').removeClass('unreachable');
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
    }

    //Setting default values for Rubber coating
    if($scope.input.belt.rubberShell == '' || typeof $scope.input.belt.rubberShell == undefined) {
        if($scope.input.application.environment == 'wet') {
            $scope.input.belt.rubberShell = 'true';
        }
        if($scope.input.application.environment == 'dry') {
            $scope.input.belt.rubberShell = 'false';
        }
    }

    //Setting default values for rubber shell coating thickness
    if($scope.input.belt.rubberShellThickness == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.rubberShellThickness = 4;
        }
        if($scope.input.systemOfMeasurement == 'imperial') {
            $scope.input.belt.rubberShellThickness = 0.16;
        }
    }


    $scope.submit = function () {
        if($scope.form.$valid){
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'belt/support');
        }
    };

    $scope.goBack = function () {
        if($scope.input.belt.type == "none"){
            $location.path($config.getBaseUrl() + 'belt/type');
        } else{
            $location.path($config.getBaseUrl() + 'belt/details');
        }
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('beltSupport', true);
            if($scope.input.belt.type == "none"){
                $config.setValid('carryingRollers', true);
            }
        } else {
            $config.setValid('beltSupport', false);
            if($scope.input.belt.type == "none"){
                $config.setValid('carryingRollers', false);
            }
        }
    });

}]);
