configApp.controller('ResultController', ['$scope', '$location', '$config', '$cookies', '$http', '$route', '$translate', '$rootScope', function($scope, $location, $config, $cookies, $http, $route, $translate, $rootScope) {

    $scope.input = $config.getInput();
    $scope.showSpinner = true;
    $scope.showResult = false;
    $scope.editablePCD = false;
    //Using the right language
    $config.updateLang();

    $scope.input.customer.lang = document.documentElement.getAttribute('lang');

    $scope.loading = false;
    $scope.loadingPdf = false;

    //Checking if page request is legal
    if (!$config.validTo('result')) {
        $location.path($config.getBaseUrl() + 'motor/voltage');
    }

    $scope.msg = "";
    $scope.msgSave = "";

    $scope.showButtons = true;

    $scope.resultContactSuccess = $config.getContactRequested();

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $scope.selectedSeries = "";
    $scope.selectedMotorType = "";
    $scope.selectedRatedPower = "";
    $scope.selectedGearType = "";
    $scope.selectedAmountPoles = "";
    $scope.matchingSeries = "";
    $scope.matchingPowers = "";
    $scope.selectedMotor = "";

    //Cutting power supply voltage to display it properly
    $scope.netVoltage = $scope.input.motor.netVoltage;
    $scope.netVoltage = $scope.netVoltage.substr($scope.netVoltage.length - 3);

    $scope.pcdValid = function() {
        if(parseFloat($scope.selectedMotor['drum_outer_diameter']) > parseFloat($scope.newPCD)) {
            $scope.pcdForm.pcd.$setValidity('pcdGreaterThanOuterDia', false);
        } else {
            $scope.pcdForm.pcd.$setValidity('pcdGreaterThanOuterDia', true);
        }
    };

    $scope.pcdValidMobile = function() {
        if(parseFloat($scope.selectedMotor['drum_outer_diameter']) > parseFloat($scope.newPCD)) {
            $scope.pcdFormMobile.pcd.$setValidity('pcdGreaterThanOuterDia', false);
        } else {
            $scope.pcdFormMobile.pcd.$setValidity('pcdGreaterThanOuterDia', true);
        }
    };

    $scope.getMotor = function() {
        $http.post($config.getServerPath() + "/api/v1/dm_result", $scope.input).then(function (data) {
            if(!data){
                $rootScope.$broadcast('overlay', {status: 0});
                return;
            }
            if(data.status != 200) {
                $rootScope.$broadcast('overlay', {status: data.status});
                return;
            }
            $scope.updateHubspot(data.data.motors, $scope.input);
            $scope.input.calculation.id = data.data.calculation_id;
            $scope.input.calculation.numberMotorsFound = data.data.numberMotorsFound;
            $scope.input.belt.discretePCD = data.data.discretePCD;
            $scope.totalBeltPull = data.data.totalBeltPull;
            $scope.beltTension = data.data.beltTension;
            $scope.exclusionCriterion = data.data.exclusionCriterion;
            $config.setInput($scope.input);
            $scope.received = data.data.motors;
            $scope.showSpinner = false;
            $scope.showResult = true;
            //Handle when no motor was found
            if ($scope.received.length == 0) {
                return;
            }

            if ($scope.input.calculation.inputsChanged) {
                $scope.selectedSeries = Object.keys($scope.received)[0];
                $scope.productSeriesChanged();
            } else {
                var noHitYet = true;
                $.each($scope.received, function (k, v) {
                    $.each(v, function (k1, v1) {
                        $.each(v1, function (k2, v2) {
                            $.each(v2, function (k3, v3) {
                                $.each(v3, function (k4, motor) {
                                    if(motor.id === $scope.input.calculation.motor_id) {
                                        noHitYet = false;
                                        $scope.selectedMotor = motor;
                                        $scope.selectedSeries = k;
                                        $scope.selectedMotorType = k1;
                                        $scope.selectedRatedPower = k2;
                                        $scope.selectedGearType = k3;
                                        $scope.selectedAmountPoles = k4;
                                    }
                                    return noHitYet;
                                });
                                return noHitYet;
                            });
                            return noHitYet;
                        });
                        return noHitYet;
                    });
                    return noHitYet;
                });
                if(noHitYet) {
                    $scope.selectedSeries = Object.keys($scope.received)[0];
                    $scope.productSeriesChanged();
                }
                $scope.motorTypes = Object.keys($scope.received[$scope.selectedSeries]);
                if($scope.motorTypes.length > 1 && $scope.motorTypes[0].toLowerCase() !== 'async'){
                    var tmp = $scope.motorTypes[0];
                    $scope.motorTypes[0] = $scope.motorTypes[1];
                    $scope.motorTypes[1] = tmp;
                }
                $scope.matchingPowers = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType]).sort();
                $scope.gearTypes = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower]);
                if($scope.gearTypes.length > 1 && $scope.gearTypes[0].toLowerCase() !== 'steel'){
                    var tmp = $scope.gearTypes[0];
                    $scope.gearTypes[0] = $scope.gearTypes[1];
                    $scope.gearTypes[1] = tmp;
                }
                $scope.amountPoles = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType]).sort();
                $scope.selectedMotor = $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles];
                $scope.input.calculation.inputsChanged = false;
                $scope.input.calculation.motorsChanged = false;
                $scope.pcd = $scope.selectedMotor['drum_outer_diameter'] + $scope.input.belt.discretePCD;
            }
            $scope.productSeries = Object.keys($scope.received);
        }, function (response) {
            $rootScope.$broadcast('overlay', {status : 0});
            $scope.received = [];
        });
    };

    //Convert motor to work as 50 Hz motor in 60 Hz net
    //global var needed to store old motors
    var origMotors = {};
    $scope.isAt60 = false;

    if(typeof $scope.selectedMotor['frequencyAt60'] != undefined) {
        if($scope.selectedMotor['frequencyAt60'] == true) {
            $scope.isAt60 = true;
        } else {
            $scope.isAt60 = false;
        }
    }

    $scope.convertMotor = function() {
        if($scope.selectedMotor['frequencyAt60'] == true) {
            $scope.input.frequencyAt60 = undefined;
            $scope.input.new_pcd = $scope.selectedMotor.pitchCircleDiameter;
            $scope.input.calculation.motor_id = $scope.selectedMotor.id;
            $http.post($config.getServerPath() + "/api/v1/dm_single", $scope.input).then(function(response) {
                $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles] = response.data;
                $scope.selectedMotor = response.data;
                $scope.isAt60 = false;
                $scope.input.new_pcd = undefined;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : 0});
            });

            $scope.isAt60 = false;
            return;
        } else if($scope.selectedMotor['frequency'] == 50) {
            $scope.input.frequencyAt60 = true;
            $scope.input.new_pcd = $scope.selectedMotor.pitchCircleDiameter;
            $scope.input.calculation.motor_id = $scope.selectedMotor.id;
            $http.post($config.getServerPath() + "/api/v1/dm_single", $scope.input).then(function(response) {
                $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles] = response.data;
                $scope.selectedMotor = response.data;
                $scope.isAt60 = true;
                $scope.input.frequencyAt60 = undefined;
                $scope.input.new_pcd = undefined;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : 0});
            });
        }
    };

    $scope.convertMotorFrom60 = function() {
        if($scope.selectedMotor['frequencyAt50'] == true) {
            $scope.input.frequencyAt50 = undefined;
            $scope.input.new_pcd = $scope.selectedMotor.pitchCircleDiameter;
            $scope.input.calculation.motor_id = $scope.selectedMotor.id;
            $http.post($config.getServerPath() + "/api/v1/dm_single", $scope.input).then(function(response) {
                $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles] = response.data;
                $scope.selectedMotor = response.data;
                $scope.isAt50 = false;
                $scope.input.new_pcd = undefined;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : 0});
            });

            $scope.isAt50 = false;
            return;
        } else if($scope.selectedMotor['frequency'] == 60) {
            $scope.input.frequencyAt50 = true;
            $scope.input.new_pcd = $scope.selectedMotor.pitchCircleDiameter;
            $scope.input.calculation.motor_id = $scope.selectedMotor.id;
            $http.post($config.getServerPath() + "/api/v1/dm_single", $scope.input).then(function(response) {
                $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles] = response.data;
                $scope.selectedMotor = response.data;
                $scope.isAt50 = true;
                $scope.input.frequencyAt50 = undefined;
                $scope.input.new_pcd = undefined;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : 0});
            });
        }
    };

    $scope.productSeriesChanged = function() {
        $scope.motorTypes = Object.keys($scope.received[$scope.selectedSeries]);
        if($scope.motorTypes.length > 1 && $scope.motorTypes[0].toLowerCase() !== 'async'){
            var tmp = $scope.motorTypes[0];
            $scope.motorTypes[0] = $scope.motorTypes[1];
            $scope.motorTypes[1] = tmp;
        }
        $scope.selectedMotorType = $scope.motorTypes[0];
        $scope.motorTypeChanged();
    };

    $scope.motorTypeChanged = function() {
        $scope.matchingPowers = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType]).sort();
        var bestPoles = Infinity;
        var smallestPower = Infinity;
        var bestGear = '';
        $.each($scope.matchingPowers, function(index, power) {
            $.each($scope.received[$scope.selectedSeries][$scope.selectedMotorType][power], function(gear, matchesGear) {
                $.each(matchesGear, function(poles, matchesPoles) {
                    if(bestPoles == 4 && $scope.selectedSeries.toLowerCase().replace(" ", "") !== 'dm0080') {
                        if(power < smallestPower) {
                            smallestPower = power;
                            bestGear = gear;
                        } else if(power <= smallestPower && bestGear === 'technopolymer') {
                            smallestPower = power;
                            bestGear = gear;
                        }
                    } else {
                        if(poles == 4 && $scope.selectedSeries.toLowerCase().replace(" ", "") !== 'dm0080') {
                            bestPoles = 4;
                            smallestPower = power;
                            bestGear = gear;
                        } else if(poles < bestPoles) {
                            bestPoles = poles;
                            smallestPower = power;
                            bestGear = gear;
                        } else if(poles <= bestPoles && power < smallestPower) {
                            bestPoles = poles;
                            smallestPower = power;
                            bestGear = gear;
                        } else if(poles <= bestPoles && power <= smallestPower && bestGear === 'technopolymer') {
                            bestPoles = poles;
                            smallestPower = power;
                            bestGear = gear;
                        }
                    }
                });
            });
        });
        $scope.selectedAmountPoles = bestPoles.toString();
        $scope.selectedRatedPower = smallestPower;
        $scope.selectedGearType = bestGear;
        $scope.gearTypes = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower]);
        $scope.amountPoles = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType]).sort();
        $scope.amountPolesChanged();
    };

    $scope.ratedPowerChanged = function() {
        $scope.gearTypes = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower]);
        var bestPoles = Infinity;
        var bestGear = '';
        $.each($scope.gearTypes, function(index, gear) {
            $.each($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][gear], function(poles) {
                if(bestPoles == 4 && $scope.selectedSeries.toLowerCase().replace(" ", "") !== 'dm0080') {
                    if(bestGear === 'technopolymer') {
                        bestGear = gear;
                    }
                } else {
                    if(poles == 4 && $scope.selectedSeries.toLowerCase().replace(" ", "") !== 'dm0080') {
                        bestPoles = 4;
                        bestGear = gear;
                    } else if(poles < bestPoles) {
                        bestPoles = poles;
                        bestGear = gear;
                    } else if(poles <= bestPoles && bestGear === 'technopolymer') {
                        bestPoles = poles;
                        bestGear = gear;
                    }
                }
            });
        });
        $scope.selectedAmountPoles = bestPoles.toString();
        $scope.selectedGearType = bestGear;
        $scope.amountPoles = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType]).sort();
        $scope.amountPolesChanged();
    };

    $scope.gearTypeChanged = function () {
        $scope.amountPoles = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType]).sort();

        var bestPoles = Infinity;
        $.each($scope.amountPoles, function(index, poles) {
            if(bestPoles !== 4 ||  $scope.selectedSeries.toLowerCase().replace(" ", "") === 'dm0080') {
                if(poles == 4 && $scope.selectedSeries.toLowerCase().replace(" ", "") !== 'dm0080') {
                    bestPoles = 4;
                } else if(poles < bestPoles) {
                    bestPoles = poles;
                }
            }
        });
        $scope.selectedAmountPoles = bestPoles.toString();
        $scope.amountPoles = Object.keys($scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType]).sort();
        $scope.amountPolesChanged();
    };

    $scope.amountPolesChanged = function() {
        $scope.selectedMotor = $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles];
        if($scope.selectedMotor.id !== $scope.input.calculation.motor_id) {
            $scope.input.calculation.motorsChanged = true;
        } else {
            $scope.input.calculation.motorsChanged = false;
        }
        $scope.pcd = $scope.selectedMotor['drum_outer_diameter'] + $scope.input.belt.discretePCD;
        $scope.editablePCD = false;
        $config.setInput($scope.input);
    };

    $scope.newCalculation = function() {
        $config.resetInput();
        $location.path($config.getBaseUrl() + 'application/name');
    };

    $scope.parseFloat = function(float) {
        return parseFloat(float);
    };

    $scope.saveConfig = function(pdf) {
        $scope.msgSave = "";
        $scope.input.calculation.inputsChanged = false;
        $scope.input.calculation.motorsChanged = false;
        $scope.input.calculation.product_series = $scope.selectedSeries;
        $scope.input.calculation.motor_type = $scope.selectedMotorType;
        $scope.input.calculation.rated_power = $scope.selectedRatedPower;
        $scope.input.calculation.gear_type = $scope.selectedGearType;
        $scope.input.calculation.number_poles = $scope.selectedAmountPoles;
        $scope.input.calculation.motor_id = $scope.selectedMotor.id;

        if(pdf !== undefined) {
            $scope.loadingPdf = true;
        } else {
            $scope.loading = true;
        }

        var postData = {
            "input" : $scope.input,
            "motor" : $scope.selectedMotor,
            "numberMotorsFound" : $scope.input.calculation.numberMotorsFound
        };

        $http.put($config.getServerPath() + "/api/v1/dm_calc/" +  $scope.input.calculation.id, postData).then(function(response) {
            if(response.status !== 200) {
                $rootScope.$broadcast('overlay', {status : response.status});
            } else if(pdf !== undefined) {
                var lang = document.getElementsByTagName("html")[0].getAttribute("lang");
                jQuery('<form action="'+ response.data.pdf +'/' + lang + '" method="GET"></form>')
                  .appendTo('body').submit().remove();
            }
            $scope.loadingPdf = false;
            $scope.loading = false;
        }, function(response) {
            $rootScope.$broadcast('overlay', {status : 0});
            $scope.loadingPdf = false;
            $scope.loading = false;
        });
    };
    $scope.editPCD = function() {
        $scope.editablePCD = true;
        $scope.newPCD = $scope.selectedMotor['pitchCircleDiameter'];
    };
    $scope.updatePCD = function(platform) {
        if(platform == 'mobile' && !$scope.pcdFormMobile.$valid) {
            return;
        }
        if(platform == 'desktop' && !$scope.pcdForm.$valid) {
            return;
        }
        $scope.newPCD = parseFloat($scope.newPCD.toString().replace(/,/g, '.'));
        if(($scope.newPCD - $scope.selectedMotor['drum_outer_diameter']) >= 0) {
            $scope.input.new_pcd = $scope.newPCD;
            $scope.input.calculation.motor_id = $scope.selectedMotor.id;
            if($scope.selectedMotor['frequencyAt60'] !== undefined) {
                $scope.input.frequencyAt60 = true;
            }
            if($scope.selectedMotor['frequencyAt50'] !== undefined) {
                $scope.input.frequencyAt50 = true;
            }
            $http.post($config.getServerPath() + "/api/v1/dm_single", $scope.input).then(function(response) {
                origMotors[response.data.id] = $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles];
                $scope.received[$scope.selectedSeries][$scope.selectedMotorType][$scope.selectedRatedPower][$scope.selectedGearType][$scope.selectedAmountPoles] = response.data;
                $scope.selectedMotor = response.data;
                $scope.input.new_pcd = undefined;
                $scope.input.frequencyAt60 = undefined;
                $scope.input.frequencyAt50 = undefined;
                $scope.selectedMotor.newPCD = $scope.newPCD;
                $scope.editablePCD = false;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : 0});
            });
        }
    };

    $scope.$on('storeForContact', function(event, args) {
        $scope.msgSave = "";
        $scope.input.calculation.inputsChanged = false;
        $scope.input.calculation.motorsChanged = false;
        $scope.input.calculation.product_series = $scope.selectedSeries;
        $scope.input.calculation.motor_type = $scope.selectedMotorType;
        $scope.input.calculation.rated_power = $scope.selectedRatedPower;
        $scope.input.calculation.gear_type = $scope.selectedGearType;
        $scope.input.calculation.number_poles = $scope.selectedAmountPoles;
        $scope.input.calculation.motor_id = $scope.selectedMotor.id;
        $scope.loading = true;
        $scope.loadingPdf = true;
        $config.setMotorsReceived($scope.received);
        $config.setSelectedMotor($scope.selectedMotor);
        $rootScope.$broadcast('readyRequestContact');
    });

    $scope.$on('finishedSalesContact', function(event, args) {
        $scope.loadingPdf = false;
        $scope.loading = false;
    });

    $scope.updateHubspot = function(motors, input) {
        $http.post($config.getServerPath() + "/api/v1/hubspot", {motors: motors, input: input}).then(function (data) {
        });
    };


}]);
