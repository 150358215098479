configApp.config(['$routeProvider', '$locationProvider', '$httpProvider', '$windowProvider', function($routeProvider, $locationProvider, $httpProvider, $windowProvider) {
    var $window = $windowProvider.$get();
    $httpProvider.defaults.withCredentials = true;
    //handling default errors by adding the $q interceptor and registrating responseError function
    $httpProvider.interceptors.push(function($q, $location, $injector) {
        return {
          request: function(config) {
            config.headers = config.headers || {};
            config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');

            return config;
          },
          responseError: function(rejection) {
              var baseUrl = '/';
                if(rejection.status <= 0) {
                    var $route = $injector.get('$route');
                    $location.path(baseUrl + 'error');
                    return;
                }
                return $q.reject(rejection);
            }
        };
    });

    var baseUrl = '/';

    var assetsUrl = '/';
    if($window.setAssetsUrl !== undefined) {
        assetsUrl = $window.setAssetsUrl;
    }
    var viewUrl = assetsUrl + 'views/';

    //Customer
    $routeProvider.when(baseUrl + 'customer', {
        templateUrl: viewUrl + 'Customer.html',
        controller: 'CustomerController',
        activetab: 'customer'
    });
    $routeProvider.when(baseUrl + 'customer/login', {
        templateUrl: viewUrl + 'CustomerLogin.html',
        controller: 'CustomerLoginController'
    });
    $routeProvider.when(baseUrl + 'customer/login/password', {
        templateUrl: viewUrl + 'CustomerForgotPassword.html',
        controller: 'CustomerLoginController'
    });
    $routeProvider.when(baseUrl + 'newpassword/:token/:email', {
        templateUrl: viewUrl + 'CustomerSetNewPassword.html',
        controller: 'CustomerLoginController'
    });
    $routeProvider.when(baseUrl + 'customer/register', {
        templateUrl: viewUrl + 'CustomerRegister.html',
        controller: 'CustomerRegisterController'
    });
    $routeProvider.when(baseUrl + 'customer/register2', {
        templateUrl: viewUrl + 'CustomerRegisterStep2.html',
        controller: 'CustomerRegisterController'
    });
    $routeProvider.when(baseUrl + 'customer/register3', {
        templateUrl: viewUrl + 'CustomerRegisterStep3.html',
        controller: 'CustomerRegisterController'
    });
    $routeProvider.when(baseUrl + 'customer/nologin', {
        templateUrl: viewUrl + 'CustomerNoLogin.html',
        controller: 'CustomerNoLoginController'
    });

    $routeProvider.when(baseUrl + 'customer/nologin/resume', {
        templateUrl: viewUrl + 'CustomerNoLoginResume.html',
        controller: 'CustomerNoLoginController'
    });

    $routeProvider.when(baseUrl + 'home/calculations', {
        templateUrl: viewUrl + 'Calculations.html',
        controller: 'CalculationsController'
    });

    $routeProvider.when(baseUrl + 'home/contactdetails', {
        templateUrl: viewUrl + 'ContactDetails.html',
        controller: 'UserSettingsController'
    });

    $routeProvider.when(baseUrl + 'home/usersettings', {
        templateUrl: viewUrl + 'UserSettings.html',
        controller: 'UserSettingsController'
    });

    $routeProvider.when(baseUrl + 'home/accountsettings', {
        templateUrl: viewUrl + 'UserAccountSettings.html',
        controller: 'UserPasswordController'
    });

    $routeProvider.when(baseUrl + 'home/calculationsettings', {
        templateUrl: viewUrl + 'CalculationSettings.html',
        controller: 'UserSettingsController'
    });

    $routeProvider.when(baseUrl + 'application/name', {
        templateUrl: viewUrl + 'ApplicationName.html',
        controller: 'ApplicationNameController'
    });

    $routeProvider.when(baseUrl + 'application/case', {
        templateUrl: viewUrl + 'ApplicationCase.html',
        controller: 'ApplicationCaseController'
    });

    $routeProvider.when(baseUrl + 'application/details', {
        templateUrl: viewUrl + 'Application.html',
        controller: 'ApplicationController'
    });

    $routeProvider.when(baseUrl + 'conveyor/type', {
        templateUrl: viewUrl + 'ConveyorType.html',
        controller: 'ConveyorTypeController'
    });

    $routeProvider.when(baseUrl + 'conveyor/details' , {
        templateUrl:  viewUrl + 'ConveyorDetails.html',
        controller: 'ConveyorDetailsController'
    });

    $routeProvider.when(baseUrl + 'conveyor/load' , {
        templateUrl:  viewUrl + 'ConveyorLoad.html',
        controller: 'ConveyorLoadController'
    });

    $routeProvider.when(baseUrl + 'conveyor/accumulation' , {
        templateUrl:  viewUrl + 'ConveyorAccumulation.html',
        controller: 'ConveyorAccumulationController'
    });

    $routeProvider.when(baseUrl + 'belt/type', {
        templateUrl: viewUrl + 'BeltType.html',
        controller: 'BeltTypeController'
    });

    $routeProvider.when(baseUrl + 'belt/details' , {
        templateUrl:  viewUrl + 'BeltDetails.html',
        controller: 'BeltDetailsController'
    });

    $routeProvider.when(baseUrl + 'belt/coating' , {
        templateUrl:  viewUrl + 'BeltCoating.html',
        controller: 'BeltCoatingController'
    });

    $routeProvider.when(baseUrl + 'belt/return' , {
        templateUrl:  viewUrl + 'BeltReturn.html',
        controller: 'BeltReturnController'
    });
    
    $routeProvider.when(baseUrl + 'belt/carrying' , {
        templateUrl:  viewUrl + 'BeltCarrying.html',
        controller: 'BeltCarryingController'
    });

    $routeProvider.when(baseUrl + 'belt/support' , {
        templateUrl:  viewUrl + 'BeltSupport.html',
        controller: 'BeltSupportController'
    });

    $routeProvider.when(baseUrl + 'operation', {
        redirectTo: '/operation/type'
    });

    $routeProvider.when(baseUrl + 'operation/type', {
        templateUrl: viewUrl + 'OperationType.html',
        controller: 'OperationTypeController'
    });

    $routeProvider.when(baseUrl + 'operation/details', {
        templateUrl: viewUrl + 'OperationDetails.html',
        controller: 'OperationDetailsController'
    });

    $routeProvider.when(baseUrl + 'motor', {
        redirectTo: '/motor/voltage'
    });

    $routeProvider.when(baseUrl + 'motor/voltage' , {
        templateUrl:  viewUrl + 'MotorVoltage.html',
        controller: 'MotorVoltageController'
    });

    $routeProvider.when(baseUrl + 'result' , {
        templateUrl:  viewUrl + 'ResultNew.html',
        controller: 'ResultController'
    });
    
    $routeProvider.when(baseUrl + 'error' , {
        templateUrl:  viewUrl + 'Error.html',
        template: '<h2>Error</h2>An error has occured retrieveing the page you have requested.',
        //controller: 'CustomerController'
    });
    $routeProvider.otherwise({
        redirectTo: baseUrl + 'customer'
    });

    $locationProvider.html5Mode(false);
}]);