configApp.controller('CalculationsController', ['$scope', '$location', '$config', '$cookies', '$http', '$filter', '$route', '$rootScope', '$translate', function($scope, $location, $config, $cookies, $http, $filter, $route, $rootScope, $translate) {
    
    $scope.hideSpinner = false;

    $scope.storedConfigurations = [];
    $http.get($config.getServerPath() + "/api/v1/dm_calc", {withCredentials: true}).then(function(response) {
        if(response.status == 200 && response.data.status == 'success') {
            $scope.storedConfigurations = response.data.calculations;
            $scope.hideSpinner = true;
            //$('#result').css('display', 'inline-block');
        } else {
            $location.path($config.getBaseUrl() + 'customer');
        }
    }, function(){
        $location.path($config.getBaseUrl() + 'customer');
    });

    $scope.newName = "";

    $scope.loading = false;

    $scope.msg = "";

    $scope.input = $config.getInput();

    if($scope.input.application.case != '' && $scope.input.application.case != null && ($scope.input.calculation.id == '' || $scope.input.calculation.id == null)){
        $scope.oldCalcAvail = true;
    } else {
        $scope.oldCalcAvail = false;
    }

    $config.setContactRequested(false);

    $scope.createNewCalculation = function() {
        var loggedIn = $scope.input.customer.isLoggedIn;
        $scope.input = $config.getResetInput();
        $scope.input.customer.isLoggedIn = loggedIn;
        $scope.input.calculation = { };
        $scope.input.calculation.name = $scope.newName;
        if($scope.input.customer.preferedSOU != '' && typeof $scope.input.customer.preferedSOU != 'undefined') {
            $scope.input.systemOfMeasurement = $scope.input.customer.preferedSOU;
        }
        $config.setInput($scope.input);
        $location.path($config.getBaseUrl() + 'application/name');
    };
    //$scope.storedConfigurations = $filter('filter')($scope.storedConfigurations, {id: 3})


    $scope.requestDeleteCalculation = function(obj) {
        obj.toBeDeleted = true;
    }

    $scope.rejectDeletion = function(obj) {
        obj.toBeDeleted = false;
    }




    $scope.deleteCalculation = function(obj) {
        $http.delete($config.getServerPath() + "/api/v1/dm_calc/" + obj.id).then(function(response){
            if(response.status == 200 && response.data.status == 'success') {
                $scope.storedConfigurations.splice($scope.storedConfigurations.indexOf(obj), 1);
            } else {
                $rootScope.$broadcast('overlay', {status : response.status});
            }
        }, function(response) {
            $rootScope.$broadcast('overlay', {status : response.status});
        });
    };

    $scope.chooseCalculation = function(obj) {
        $translate('ERROR_400').then(function (translation) {
            $scope.msg = translation;
        });
        if(obj.discontinued) {
            $rootScope.$broadcast('overlay', {
                header: 'DISCONTINUED_WARNING_HEADER',
                message : 'DISCONTINUED_WARNING_MESSAGE',
                dismissButtonText: 'DISCONTINUED_WARNING_DISMISS',
                confirmButtonText: 'DISCONTINUED_WARNING_CONFIRM',
                callback: function() {
                    var saved_input = JSON.parse(obj.input);

                    $scope.input.calculation = saved_input.calculation;
                    $scope.input.calculation.id = obj.id;
                    $scope.input.application = saved_input.application;
                    $scope.input.conveyor = saved_input.conveyor;
                    $scope.input.belt = saved_input.belt;
                    $scope.input.operation = saved_input.operation;
                    $scope.input.motor = saved_input.motor;
                    $scope.input.valid = saved_input.valid;
                    $scope.input.index = saved_input.index;
                    $scope.input.systemOfMeasurement = saved_input.systemOfMeasurement;
                    $config.setInput($scope.input);
                    $location.path($config.getBaseUrl() + 'result');
                },
                dismissCallback: function() {

                    var postData = {
                        "input" : JSON.parse(obj.input),
                        "motor" : JSON.parse(obj.calculated_motor),
                        "numberMotorsFound" : obj.numberOfSuitableMotors
                    };
                    $http.put($config.getServerPath() + "/api/v1/dm_calc/" +  obj.id, postData).then(function(response) {
                        if(response.status !== 200) {
                            $rootScope.$broadcast('overlay', {status : response.status});
                        } else {
                            var lang = document.getElementsByTagName("html")[0].getAttribute("lang");
                            jQuery('<form action="'+ response.data.pdf +'/' + lang + '" method="GET"></form>')
                                .appendTo('body').submit().remove();
                        }
                    }, function(response) {
                        $rootScope.$broadcast('overlay', {status : 0});
                    });
                },
            });
            return;
        }

        var saved_input = JSON.parse(obj.input);

        $scope.input.calculation = saved_input.calculation;
        $scope.input.calculation.id = obj.id;
        $scope.input.application = saved_input.application;
        $scope.input.conveyor = saved_input.conveyor;
        $scope.input.belt = saved_input.belt;
        $scope.input.operation = saved_input.operation;
        $scope.input.motor = saved_input.motor;
        $scope.input.valid = saved_input.valid;
        $scope.input.index = saved_input.index;
        $scope.input.systemOfMeasurement = saved_input.systemOfMeasurement;
        $config.setInput($scope.input);
        $location.path($config.getBaseUrl() + 'result');
    };

    $scope.resumeCalculation = function () {
        $location.path($config.getBaseUrl() + 'application/name');
    }
}]);
