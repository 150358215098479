$(document).ready(function() {
    $('.config-teaser').flexslider({
        animation: "fade",
        slideshow : false,
        controlNav : false,
        directionNav : false,
        startAt: 0,
        slideshowSpeed : 30000,
        animationSpeed: 1000,
        pauseOnAction : false
    });
});
