configApp.controller('ConveyorAccumulationController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('conveyorAccumulation')) {
        $location.path($config.getBaseUrl() + 'conveyor/load');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;



    if($scope.input.conveyor.frictionForce == '') {
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.conveyor.frictionForce = 50;
        } else {
            $scope.input.conveyor.frictionForce = 11.24;
        }
    }


    $scope.submit = function () {
        if($scope.form.$valid){
            $location.path($config.getBaseUrl() + 'belt/type');
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'conveyor/load');
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('beltType', true);
        } else {
            $config.setValid('beltType', false);
        }
    });
}]);
