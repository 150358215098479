configApp.controller('MainController', ['$scope', '$config', '$location', '$route', '$window', '$http', '$cookies', '$routeParams', '$rootScope', '$translate', function($scope, $config, $location, $route, $window, $http, $cookies, $routeParams, $rootScope, $translate) {

    //Using the right language
    $config.updateLang();

    $scope.input = $config.getInput();

    $scope.baseUrl = '#';
    if($window.setBaseUrl !== undefined) {
        $scope.baseUrl = $window.setBaseUrl + '#';
    }

    $scope.imgUrl = $config.getAssetsUrl() + 'img/';

    $scope.disabled = function(name) {
        return !$config.validTo(name);
    };

    //Initializing right language
    var lang = document.getElementsByTagName("html")[0].getAttribute("lang");
    if(lang === 'fr') {
        $translate.use('fr');
    } else if(lang === 'it') {
        $translate.use('it');
    } else if(lang === 'es') {
        $translate.use('es');
    } else if(lang === 'de') {
        $translate.use('de');
    } else if(lang === 'tr') {
        $translate.use('tr');
    } else if(lang === 'cn' || lang === 'zh') {
        $translate.use('cn');
    }

    $scope.is_current = function (path) {
        if (typeof path === 'string' && $location.path() === $config.getBaseUrl() + path) {
            return 'is-current';
        } else if(Array.isArray(path)) {
            for (var i = 0; i < path.length; i++) {
                if($location.path() === $config.getBaseUrl() + path[i])
                    return 'is-current';
            }
        }
        return '';
    };

    $scope.index = $config.index;

    $scope.resumablePage = true;

    $window.addEventListener('beforeunload', function() {
        $config.dataToCookie();
    });

    $scope.$watch(function() {return $config.getValid()}, function() {
        $scope.input.valid = $config.getValid();
    }, true);

    $scope.$watch(function() {return $location.path()}, function(){
        var actPath = $location.path();
        $scope.resumablePage = actPath == '/home/calculations' || actPath == '/home/contactdetails' || actPath == '/home/usersettings' || actPath == '/home/calculationsettings' || actPath == '/customer' || actPath == '/customer/login' || actPath == '/register' || actPath == '/customer/register' || actPath == '/customer/nologin';
    });



    if($location.url().split('/')[1] != 'newpassword' && $location.path().split('/')[1] != 'error') {
        $http.get($config.getServerPath() + "/api/v1/user/get").then(function(response) {
            if(response.status == 200 && response.data.status == 'loggedin') {
                $scope.input.customer = response.data.customer;
                $scope.input.customer.isLoggedIn = true;
                $scope.input.preferedSOU = response.data.customer.preferedSOU;
                $config.setInput($scope.input);
                $location.path($config.getBaseUrl() + 'home/calculations');
            } else if (response.status == 200) {
                $location.path($config.getBaseUrl() + 'customer');
            } else {
                $rootScope.$broadcast('overlay', {status : response.status});
                $location.path($config.getBaseUrl() + 'customer');
            }
        }, function(response){
            $rootScope.$broadcast('overlay', {status : response.status});
            $location.path($config.getBaseUrl()    + 'customer');
        });
    }

    $scope.resumecalculation = function() {
        var inputCookie = $cookies.get('input');
        if(inputCookie != null && typeof inputCookie !== 'undefined'){
            var saved_input = JSON.parse(inputCookie);
            $scope.input.calculation = saved_input.calculation;
            $scope.input.application = saved_input.application;
            $scope.input.conveyor = saved_input.conveyor;
            $scope.input.belt = saved_input.belt;
            $scope.input.operation = saved_input.operation;
            $scope.input.motor = saved_input.motor;
            $scope.input.valid = saved_input.valid;
            $scope.input.index = saved_input.index;
            $scope.input.systemOfMeasurement = saved_input.systemOfMeasurement;
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'application/name');
        }
    };

    $scope.goToLogin = function () {
        $location.path($config.getBaseUrl() + 'customer/login');
    };

    $scope.logout = function () {
        $http.get($config.getServerPath() + '/api/v1/user/logout').then(function (data) {
            if(data.status == 200) {
                $scope.input.customer.isLoggedIn = false;
                $config.setInput($scope.input);
                $location.path($config.getBaseUrl() + 'customer');
            } else {
                $rootScope.$broadcast('overlay', {status : data.status});
            }
        }, function(data) {
            $rootScope.$broadcast('overlay', {status : data.status});
        });
    };

    //For testing
    $scope.totalReset = function() {
        $config.resetCustomer();
        $config.setInput($config.getResetInput());
    };

}]);
