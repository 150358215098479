configApp.controller('OverlayController', ['$scope', '$location', '$config',  '$translate', '$route', function($scope, $location, $config, $translate, $route) {
    $scope.visible = false;
    
    $scope.$on('overlay', function(event, args) {
        $scope.visible = true;
        $scope.msg = "";
        $scope.heading = 'Error';
        $scope.isError = false;
        $scope.isMessage = false;
        $scope.hasDismissCallback = args.dismissCallback !== undefined;
        $scope.dismissButtonText = args.dismissButtonText !== undefined ? args.dismissButtonText : 'Dismiss';
        $scope.confirmButtonText = args.confirmButtonText !== undefined ? args.confirmButtonText : 'Confirm';

        $scope.confirm = function() {
            $scope.visible = false;
            if(args.callback != undefined) {
                args.callback();
            }
            if(args.successCallback != undefined) {
                args.successCallback();
            }
        };

        $scope.dismiss = function() {
            $scope.visible = false;
            if(args.dismissCallback != undefined) {
                args.dismissCallback();
            }
        };

        $scope.reload = function() {
            location.reload();
        };

        if(args.status != undefined) {
            $scope.isError = true;
            switch(args.status) {
                case 400:
                    $translate('ERROR_400').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 403:
                    $translate('ERROR_403').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 404:
                    $translate('ERROR_404').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 408:
                    $translate('ERROR_408').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 422:
                    $translate('ERROR_422').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 429:
                    $translate('ERROR_429').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 500:
                    $translate('ERROR_500').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 503:
                    $translate('ERROR_503').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 504:
                    $translate('ERROR_504').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
                case 'DISCONTINUED':
                    $translate('DISCONTINUED').then(function (translation) {
                        $scope.msg = translation;
                    });
                    break;
                default:
                    $translate('ERROR_DEFAULT').then(function (translation) {
                      $scope.msg = translation;
                    });
                    break;
            }
        } else if(args.message != undefined) {
            $scope.isMessage = true;
            $scope.msg = args.message;
            $scope.heading = args.header;
        }
    });

    return {
        openDialog :function(){
            alert(123);
        }
    }
    
}]);
