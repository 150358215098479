configApp.controller('CustomerNoLoginController', ['$scope', '$translate', '$location', '$config', '$cookies', '$http', '$route', '$rootScope', function($scope, $translate, $location, $config, $cookies, $http, $route, $rootScope) {

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $scope.input = $config.getInput();
    $scope.indexHigh = $config.getIndex();
    $scope.selected = '';

    $scope.favCountries = $config.getFavoriteCountries();
    $scope.countries = $config.getCountriesObject();

    $scope.sent = false;

    $http.get($config.getServerPath() + "/api/v1/user/get").then(function(response) {
        if(response.status == 200 && response.data.status == 'loggedin') {
            $scope.input.customer = response.data.customer;
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'home/calculations');
        } else if(response.status !== 200) {
            $rootScope.$broadcast('overlay', {status : response.status});
        }
    }, function(response) {
        $rootScope.$broadcast('overlay', {status : response.status});
    });

    $config.setContactRequested(false);

    $scope.proceedNotRegistered = function () {
        if($scope.form.$valid) {
            $config.setInput($scope.input);
            if($scope.input.application.case != '') {
                $location.path($config.getBaseUrl() + 'customer/nologin/resume');
            } else {
                $location.path($config.getBaseUrl() + 'application/name');
            }
        } else {
            $scope.sent = true;
        }
    };

    $scope.resume = function () {
        if($scope.selected == 'new') {
            $scope.input = $config.getResetInput();
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'customer/nologin');
        } else {
            if($scope.selected != 'resume'){
                return;
            }
            $location.path($config.getBaseUrl() + 'application/name');
        }
    }

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'customer');
    };

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$invalid) {
            $config.setIndex(0);
        }
        if($scope.form.$valid) {
            if(1 > $scope.indexHigh) {
                $config.setIndex(1);
            } else {
                $config.setIndex($scope.indexHigh);
            }
        }
    });


}]);
