var globalRadioSelect;

function setuphelp(){


  $('.infohover.text-input').hover(function(){
  	arrowtop = $(this).position().top + $(this).children('input, select').position().top + ($(this).children('input, select').outerHeight() / 2) - 20;
  	$('.arrow').css('top', arrowtop );
  	$('.description').html($(this).children('.text').html());
  });

    //Split for reset after mouse leave
  $('.infohover.radio-input').mouseenter(function(){
    arrowtop = $(this).position().top + ($(this).find('.overlay').outerHeight() / 2) + $(this).find('span').outerHeight() - 20;
    $('.arrow').css('top', arrowtop );
    $('.description').html($(this).children('.text').html());
  });

    $('.infohover.radio-input').click(function(){
        globalRadioSelect = $(this);
    });

    $('.infohover.radio-input').mouseleave(function(){
        if(globalRadioSelect != null){
            arrowtop = globalRadioSelect.position().top + (globalRadioSelect.find('.overlay').outerHeight() / 2) + globalRadioSelect.find('span').outerHeight() - 20;
            $('.arrow').css('top', arrowtop );
            $('.description').html(globalRadioSelect.children('.text').html());
        }
  });
    //END Split

  $('.infohover.select-input').hover(function(){
    arrowtop = $(this).position().top + $(this).find('.custom-select').position().top + ($(this).find('select').outerHeight() / 2) - 20;
    $('.arrow').css('top', arrowtop );
    $('.description').html($(this).children('.text').html());
  });

    //SAME FOR FOCUS
    $('.infohover.text-input').hover(function(){
  	arrowtop = $(this).position().top + $(this).children('input, select').position().top + ($(this).children('input, select').outerHeight() / 2) - 20;
  	$('.arrow').css('top', arrowtop );
  	$('.description').html($(this).children('.text').html());
  });

  $('.infohover.radio-input').focus(function(){
    arrowtop = $(this).position().top + ($(this).find('.overlay').outerHeight() / 2) + $(this).find('span').outerHeight() - 20;
    $('.arrow').css('top', arrowtop );
    $('.description').html($(this).children('.text').html());
  });

  $('.infohover.select-input').focus(function(){
    arrowtop = $(this).position().top + $(this).find('.custom-select').position().top + ($(this).find('select').outerHeight() / 2) - 20;
    $('.arrow').css('top', arrowtop );
    $('.description').html($(this).children('.text').html());
  });
    //END SAME FOR FOCUS

  $('.infohover .helpbutton').click(function(){
    $(this).siblings('.text').slideToggle();
  });
  $('.infohover .text').click(function(){
    $(this).slideToggle();
  });

  $('.helpbuttontop').click(function(){
    $(this).siblings('.helptexttop').slideToggle();
  });
  $('.helptexttop').click(function(){
    $(this).slideToggle();
  });

  //$('.infohover input, .infohover select').focus(inputevent).keypress(inputevent);
  //$('.description').html($compile($('.text').html()))(scope);
    //$compile(angular.element("my html"))(scope)
  //$('.description').attr('translate', $('.text').attr('translate'));;




};


function inputevent(){
	arrowtop = $(this).parent().position().top + $(this).position().top + ($(this).outerHeight() / 2) - 20;
	//arrowtop = $(this).position().top;
	$('.arrow').css('top', arrowtop );

	$('.description').html($(this).siblings('.text').html());
}

$(document).ready(hideMenu);
$(window).resize(hideMenu);

function hideMenu(){
    var windowWidth = $(window).width();
    if(windowWidth < 991) {
        $('#burger').show();
        $('.sidenav').hide();
    } else{
        $('#burger').hide();
        $('.sidenav').show();
    }
}

$(document).ready(bindToggle);

var rotated = false;

function bindToggle(){
    $('#sec-nav').click(function(){
        $('.sidenav').slideToggle();
        if(rotated) {
            $('#sec-nav>span').attr('rotated', 'false');
        } else {
            $('#sec-nav>span').attr('rotated', 'true');
        }
        rotated = !rotated;
    });
}

function confirmDeletion(){
    $('.delete').click(function() {
        $(this).parent().siblings('.confirmation').css('display', 'inline-block');
        $(this).parent().css('display', 'none');
    });
}

function cancelDeletion(){
    $('.delete-no').click(function() {
        $(this).parent().siblings('.selection').css('display', 'inline-block');
        $(this).parent().css('display', 'none');
    });
}

$(document).ready(confirmDeletion);




/*function adjustTableHeight() {
    var leftTables = $("table.left-table");
    var rightTables = $("table.right-table");

    leftTables.each(function(index) {
        $(this).find('tr').each(function(indextd) {
            console.log("Detected left height: " + $(this).height() + " OF " + $(this).html());
            var rightEquiv = $(rightTables.get(index)).find('tr').get(indextd);
            if($(rightEquiv).height() < $(this).height() ) {
                $(rightEquiv).height($(this).height());
            } else {
                $(this).height($(rightEquiv).height());
            }
        });
    })
}*/

//$(document).ready(adjustTableHeight);
