configApp.controller('ApplicationNameController', ['$scope', '$rootScope', '$config', '$location', '$route', function($scope, $rootScope, $config, $location, $route) {

    //Vars
    $scope.input = $config.getInput();

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    //functions
    $scope.submit = function() {
        if($scope.form.$valid){
            $location.path($config.getBaseUrl() + 'application/case');
        }
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });
}]);