configApp.controller('UserPasswordController', ['$scope', '$location', '$config', '$cookies','$http', '$rootScope',  function($scope, $location, $config, $cookies, $http, $rootScope) {

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $config.setIndex(0);


    //Vars
    $scope.selected;
    $scope.registered = false;

    $scope.input = $config.getInput();

    $scope.oldpassword = "";
    $scope.newpassword = "";
    $scope.repeatnewpassword = "";
    $scope.loading = false;
    $scope.msg = "";

    $scope.save = function() {
        if($scope.form.$valid && ($scope.newpassword == $scope.repeatnewpassword)) {
            var data = {
                firstName: $scope.input.customer.firstName,
                lastName: $scope.input.customer.lastName,
                company : $scope.input.customer.company,
                lang : $scope.input.customer.lang,
                email : $scope.input.customer.email,
                country : $scope.input.customer.country,
                street : $scope.input.customer.street,
                zip : $scope.input.customer.zip,
                city : $scope.input.customer.city,
                phonenumber : $scope.input.customer.phonenumber,
                usertype : $scope.input.customer.usertype,
                interest : $scope.input.customer.interest,
                oldpassword: $scope.oldpassword,
                newpassword: $scope.newpassword,
                repeatnewpassword: $scope.repeatnewpassword,
                preferedSOU: $scope.input.customer.preferedSOU
            };
            $scope.loading = true;
            $http.put($config.getServerPath() + "/api/v1/user/update", data).then(function(response) {
                if(response.status == 200 && response.data.status == 'success') {
                    $scope.input.customer = response.data.customer;
                    $scope.input.customer.isLoggedIn = true;
                    $scope.msg = response.data.msg;
                    $scope.oldpassword = "";
                    $scope.newpassword = "";
                    $scope.repeatnewpassword = "";
                }
                else {
                    $scope.msg = response.data.msg;
                }
                $scope.loading = false;
            }, function(response) {
                $rootScope.$broadcast('overlay', {status : response.status});
                $scope.loading = false;
            });
        }
    };

}]);