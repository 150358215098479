configApp.controller('MotorVoltageController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('motorVoltage')) {
        $location.path($config.getBaseUrl() + 'operation/details');
    }

    if($scope.input.operation.type === "startStop") {
        $scope.input.motor.frequencyInverter = "true";
    }

    if($scope.input.operation.type === "startStop" && $scope.input.motor.netVoltage.substr(0,1) === '1') {
        $scope.input.motor.netVoltage = '3x230';
    }

    if($scope.input.belt.type !== "friction" && $scope.input.motor.netVoltage.substr(0,1) === '1') {
        $scope.input.motor.netVoltage = '3x230';
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;
    
    $scope.defaultFreq = function() {
        $scope.calcChanged();
        if($scope.input.motor.netVoltage == '1x115' || $scope.input.motor.netVoltage == '3x460' || $scope.input.motor.netVoltage == '3x575') {
            $scope.input.motor.netFrequency = '60';
        } else {
            $scope.input.motor.netFrequency = '50';
        }
    };
    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.submit = function () {
        if($scope.form.$valid){
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'result');
        }
    };

    $scope.back = function () {
        if($scope.input.operation.type == "continuous") {
            $location.path($config.getBaseUrl() + 'operation/type');
        } else {
            $location.path($config.getBaseUrl() + 'operation/details');
        }
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('result', true);
        } else {
            $config.setValid('result', false);
        }
    });
}]);
