configApp.controller('BeltCarryingController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('carryingRollers')) {
        $location.path($config.getBaseUrl() + 'belt/support');
    }
    
    if($scope.input.belt.support == 'slider') {
        $location.path($config.getBaseUrl() + 'belt/return');
    }

    if($scope.input.belt.type == "none") {
        $('#beltDetails').addClass('unreachable');
    } else {
        $('#beltDetails').removeClass('unreachable');
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
    }


    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $scope.submit = function () {
        if($scope.form.$valid){
            $config.setInput($scope.input);
            if($scope.input.belt.type == "none") {
                $location.path($config.getBaseUrl() + 'operation/type');
            } else {
                $location.path($config.getBaseUrl() + 'belt/return');
            }
        }
    };
    
    $scope.innerOuter = function() {
        $scope.input.calculation.inputsChanged = true;
        if($scope.input.systemOfMeasurement == 'metric') {
            if(parseFloat($scope.input.belt.carryingRollers.innerDiameter) + 1 > parseFloat($scope.input.belt.carryingRollers.outerDiameter)){
                $scope.form.carryingOuter.$setValidity('innerGreaterThanOuter', false);
            } else {
                $scope.form.carryingOuter.$setValidity('innerGreaterThanOuter', true);
            }
        } else {
            if(parseFloat($scope.input.belt.carryingRollers.innerDiameter) + 0.04 > parseFloat($scope.input.belt.carryingRollers.outerDiameter)){
                $scope.form.carryingOuter.$setValidity('innerGreaterThanOuter', false);
            } else {
                $scope.form.carryingOuter.$setValidity('innerGreaterThanOuter', true);
            }
        }
    };


    if($scope.input.belt.carryingRollers.material == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.carryingRollers.material = '7.85';
        } else {
            $scope.input.belt.carryingRollers.material = '7.85';
        }
    }

    if($scope.input.belt.carryingRollers.innerDiameter == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.carryingRollers.innerDiameter = 57;
        } else {
            $scope.input.belt.carryingRollers.innerDiameter = 2.24;
        }
    }

    if($scope.input.belt.carryingRollers.outerDiameter == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.carryingRollers.outerDiameter = 60;
        } else {
            $scope.input.belt.carryingRollers.outerDiameter = 2.36;
        }
    }


    if($scope.input.belt.carryingRollers.quantity == '' || $scope.input.belt.carryingRollers.quantity == null){
        $config.setInput($scope.input);
        $config.updateCarryingRollersQuantity();
        $scope.input = $config.getInput();
    }

    $scope.outerDiameterChanged = function () {
        $config.setInput($scope.input);
        $config.updateCarryingRollersQuantity();
        $scope.input = $config.getInput();
    }

    $scope.goBack = function () {
        if($scope.input.belt.type == "none"){
            $location.path($config.getBaseUrl() + 'belt/coating');
        } else {
            $location.path($config.getBaseUrl() + 'belt/support');
        }
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function() {
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('beltCarrying', true);
            if($scope.input.belt.type == 'none') {
                $config.setValid('operationType', true);
            }
        } else {
            $config.setValid('beltCarrying', false);
            if($scope.input.belt.type == 'none') {
                $config.setValid('operationType', false);
            }
        }
    });
}]);
