angular.module('translateModule', ['pascalprecht.translate', 'ngSanitize']).config(function ($translateProvider) {
    var assetsUrl = '/';
    if(window.setAssetsUrl !== undefined) {
        assetsUrl = window.setAssetsUrl;
    }
    //Load additional languages
    $translateProvider.useStaticFilesLoader({
        'prefix': assetsUrl + 'js/translations/locale-',
        'suffix': '.json'
    });

    // Standard language
    $translateProvider.preferredLanguage('en');
    $translateProvider.useSanitizeValueStrategy('sanitize');
    $translateProvider.fallbackLanguage('en');
});
