configApp.controller('ConveyorDetailsController', ['$scope', '$location', '$config', '$route', '$translate', function($scope, $location, $config, $route, $translate) {

    $scope.input = $config.getInput();

    var errorMsg = "";

    $translate('CONVEYOR_HEIGHT_DIFF_ERROR').then(function (text) {
        errorMsg = text;
    }, function (translationId) {
        errorMsg = translationId;
    });

    //Checking if page request is legal
    if(!$config.validTo('conveyorDetails')) {
        $location.path($config.getBaseUrl() + 'conveyor/type');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $scope.submit = function () {
        if($config.textToFloat($scope.input.conveyor.heightDifference) > $config.textToFloat($scope.input.conveyor.inclinationLength)){
            $scope.invalidHeightDiff = true;
            $scope.form.heightDiff.$setValidity('greaterThanIncliLength', false);
            $('input[name="heightDiff"]').get(0).setCustomValidity(errorMsg);
            return;
        }
        if(!$scope.invalidHeightDiff && $scope.form.$valid) {
            //$config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'conveyor/load');
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'conveyor/type');
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.angleChanged = function() {
        $('input[name="heightDiff"]').get(0).setCustomValidity("");
        if($scope.form.incliAngle.$error.pattern === true) {
            return;
        }
        if($config.textToFloat($scope.input.conveyor.inclinationLength) && $config.textToFloat($scope.input.conveyor.inclinationAngle)) {
            $scope.input.conveyor.heightDifference = ($config.textToFloat($scope.input.conveyor.inclinationLength) *
            Math.sin($config.textToFloat($scope.input.conveyor.inclinationAngle) * Math.PI / 180))
                .toFixed(2);
            $scope.invalidHeightDiff = false;
            $scope.form.heightDiff.$setValidity('greaterThanIncliLength', true);
        }
    };

    $scope.heightDiffChanged = function() {
        $('input[name="heightDiff"]').get(0).setCustomValidity("");
        if($scope.form.heightDiff.$error.pattern === true) {
            return;
        }
        if($config.textToFloat($scope.input.conveyor.heightDifference) > $config.textToFloat($scope.input.conveyor.inclinationLength)){
            $scope.invalidHeightDiff = true;
            $scope.form.heightDiff.$setValidity('greaterThanIncliLength', false);
            return;
        } else {
            $scope.invalidHeightDiff = false;
            $scope.form.heightDiff.$setValidity('greaterThanIncliLength', true);
        }
        if($scope.input.conveyor.heightDifference && $scope.input.conveyor.inclinationLength) {
            $scope.input.conveyor.inclinationAngle = (Math.asin($config.textToFloat($scope.input.conveyor.heightDifference) /
                $config.textToFloat($scope.input.conveyor.inclinationLength))
            * 180 / Math.PI)
                .toFixed(2);
        }
    };

    $scope.incliLengthChanged = function() {
        $('input[name="heightDiff"]').get(0).setCustomValidity("");
        if($scope.form.incliLength.$error.pattern === true) {
            return;
        }
        if($config.textToFloat($scope.input.conveyor.heightDifference) > $config.textToFloat($scope.input.conveyor.inclinationLength)){
            $scope.invalidHeightDiff = true;
            $scope.form.heightDiff.$setValidity('greaterThanIncliLength', false);
            return;
        } else {
            $scope.invalidHeightDiff = false;
            $scope.form.heightDiff.$setValidity('greaterThanIncliLength', true);
        }
        if($scope.input.conveyor.inclinationAngle && $scope.input.conveyor.inclinationLength) {
            $scope.input.conveyor.heightDifference = ($config.textToFloat($scope.input.conveyor.inclinationLength) *
            Math.sin($config.textToFloat($scope.input.conveyor.inclinationAngle) * Math.PI / 180))
                .toFixed(2);
        }
    };


    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
        //Update carrying and return rollers quantity immediately on leave
        $config.updateCarryingRollersQuantity();
        $config.updateReturnRollersQuantity();
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('conveyorLoad', true);
        } else {
            $config.setValid('conveyorLoad', false);
        }
    });

}]);
