configApp.controller('OperationTypeController', ['$scope', '$config', '$location', '$route', function($scope, $config, $location, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('operationType')) {
        $location.path($config.getBaseUrl() + 'belt/return');
    }

    $scope.initialType = $scope.input.operation.type;

    //Setting up help box correctly
    switch($scope.initialType) {
        case 'continuous':
            globalRadioSelect = $('#one').parent().parent();
            break;
        case 'startStop':
            globalRadioSelect = $('#two').parent().parent();
            break;
        default:
            globalRadioSelect = null;
            break;
    }

    if($scope.initialType == "continuous"){
        $('#operationDetails').addClass('unreachable');
    } else {
        $('#operationDetails').removeClass('unreachable');
    }

    $scope.submit = function () {
        if($scope.input.operation.type == "continuous"){
            $location.path($config.getBaseUrl() + 'motor/voltage');
        } else if($scope.input.operation.type == "startStop"){
            $location.path($config.getBaseUrl() + 'operation/details');
        }
    };

    $scope.back = function () {
        if($scope.input.belt.type == "none"){
            $location.path($config.getBaseUrl() + 'belt/carrying');
        } else {
            $location.path($config.getBaseUrl() + 'belt/return');
        }
    };

    $scope.$on("$destroy", function(){
        if($scope.input.operation.type === 'startStop') {
            $scope.input.motor.frequencyInverter = 'true';
            if(!$scope.input.motor.speedAccuracy) {
                $scope.input.motor.speedAccuracy = '10';
            }
        }
        $config.setInput($scope.input);
    });

    $scope.changed = function() {
        $scope.input.calculation.inputsChanged = true;
        if($scope.initialType != $scope.input.operation.type) {
            if($scope.initialType == 'continuous') {
                $config.setValid('motorVoltage', false);
            }
            if($scope.input.operation.type == 'continuous') {
                $config.setValid('operationDetails', true);
                $config.setValid('motorVoltage', true);
                $config.setValid('result', true);
            }
            if($scope.input.operation.type == 'startStop') {
                $config.setValid('operationDetails', true);
                $config.setValid('result', false);
            }
        }
        if($scope.input.operation.type == "continuous") {
            $('#operationDetails').addClass('unreachable');
        } else {
            $('#operationDetails').removeClass('unreachable');
        }
    };
}]);
