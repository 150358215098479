configApp.controller('ConveyorLoadController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('conveyorLoad')) {
        $location.path($config.getBaseUrl() + 'conveyor/details');
    }


    //Setting to null to ensure correct help box
    globalRadioSelect = null;




    $scope.submit = function () {
        if($scope.form.$valid){
            //$config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'conveyor/accumulation');
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'conveyor/details');
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('conveyorAccumulation', true);
        } else {
            $config.setValid('conveyorAccumulation', false);
        }
    });
}]);