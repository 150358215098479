configApp.controller('OperationDetailsController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('operationDetails')) {
        $location.path($config.getBaseUrl() + 'operation/type');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    if($scope.input.operation.type != "startStop"){
        if($config.getValidValue('motorVoltage')){
            $location.path($config.getBaseUrl() + 'motor/voltage');
        } else {
            $location.path($config.getBaseUrl() + 'operation/type');
        }
    }

    $scope.submit = function () {
        if($scope.form.$valid){
            $location.path($config.getBaseUrl() + 'motor/voltage');
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'operation/type');
    };
    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('motorVoltage', true);
        } else {
            $config.setValid('motorVoltage', false);
        }
    });
}]);
