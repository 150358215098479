configApp.controller('ApplicationCaseController', ['$scope', '$config', '$location', '$route', function($scope, $config, $location, $route) {
    //Vars
    $scope.input = $config.getInput();

    $scope.initialType = $scope.input.application.case;

    //Setting up help box correctly
    switch($scope.initialType) {
        case 'foodWashdown':
            globalRadioSelect = $('#one').parent().parent();
            break;
        case 'foodDry':
            globalRadioSelect = $('#two').parent().parent();
            break;
        case 'airport':
            globalRadioSelect = $('#three').parent().parent();
            break;
        case 'dryLogistic':
            globalRadioSelect = $('#five').parent().parent();
            break;
        default:
            globalRadioSelect = null;
            break;

    }

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'application/name');
    };


    //functions
    $scope.submit = function() {
        if($scope.input.application.case != ''){
            $location.path($config.getBaseUrl() + 'application/details');
        }
    };
    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() {return $scope.input.application.case}, function() {
        if($scope.initialType == $scope.input.application.case && $scope.initialType != ""){

        }
        else {
            if($scope.input.application.case != '' && typeof $scope.input.application.case !== 'undefined'){
                $config.setValid('applicationDetails', true);
            } else{
                $config.setValid('applicationDetails', false);
            }
        }
    });
    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    //necessary to ensure continuing is possible with initial type from cookie
    //$config.setValid(1, true);
}]);