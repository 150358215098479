configApp.controller('BeltSupportController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('beltSupport')) {
        $location.path($config.getBaseUrl() + 'belt/coating');
    }


    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    $scope.initialType = $scope.input.belt.support;

    //Function getting called when select changes to adjust default values for Friction factor
    $scope.change = function() {
        $scope.input.calculation.inputsChanged = true;
        if($scope.input.belt.support == 'slider') {
            $('#carryingRollers').addClass('unreachable');
            if($scope.input.belt.frictionFactor == 0.05) {
                $scope.input.belt.frictionFactor = 0.3;
            }
        }
        if($scope.input.belt.support == 'roller') {
            $('#carryingRollers').removeClass('unreachable');
            if($scope.input.belt.frictionFactor == 0.3) {
                $scope.input.belt.frictionFactor = 0.05;
            }
        }
    };

    if($scope.initialType == "slider") {
        $('#carryingRollers').addClass('unreachable');
    } else {
        $('#carryingRollers').removeClass('unreachable');
    }

    if($scope.input.belt.type == "none") {
        $('#beltDetails').addClass('unreachable');
    } else {
        $('#beltDetails').removeClass('unreachable');
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
    }



    $scope.submit = function () {
        if($scope.form.$valid){
            $config.setInput($scope.input);
            if($scope.input.belt.support == 'roller') {
                $location.path($config.getBaseUrl() + 'belt/carrying');
            } else {
                $location.path($config.getBaseUrl() + 'belt/return');
            }
        }
    };

    $scope.goBack = function () {
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous"){
            $location.path($config.getBaseUrl() + 'belt/details');
        } else{
            $location.path($config.getBaseUrl() + 'belt/coating');
        }
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            if($scope.input.belt.support == 'slider') {
                $config.setValid('carryingRollers', true);
                $config.setValid('beltCarrying', true);
            } else {
                $config.setValid('carryingRollers', true);
            }
        } else {
            $config.setValid('carryingRollers', false);
        }
    });


}]);
