configApp.controller('SalesContactController', ['$scope', '$config', '$rootScope', '$http', function($scope, $config, $rootScope, $http) {

    $scope.requestContact = function () {
        $scope.loadingContact = true;

        $rootScope.$broadcast('storeForContact');
    };

    $scope.$on('readyRequestContact', function(event, args) {
        $scope.input = $config.getInput();
        $http.post($config.getServerPath() + "/api/v1/contact", {
            motors: $config.getMotorsReceived(),
            input: $scope.input,
            selectedMotor: $config.getSelectedMotor(),
            numberMotorsFound : $scope.input.calculation.numberMotorsFound
        }).then(function (data) {
            $rootScope.$broadcast('finishedSalesContact');
            if (data.status == 200) {
                $scope.resultContactSuccess = true;
                $config.setContactRequested(true);

                $scope.loadingContact = false;
            } else {
                $rootScope.$broadcast('overlay', {status: data.status});
                $scope.loadingContact = false;
            }
        }, function (response) {
            $rootScope.$broadcast('finishedSalesContact');
            $rootScope.$broadcast('overlay', {status: 0});
            $scope.loadingContact = false;
        });
    });


}]);
