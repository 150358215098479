configApp.controller('BeltDetailsController', ['$scope', '$location', '$config', '$route',  function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('beltDetails')) {
        $location.path($config.getBaseUrl() + 'belt/type');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;



    if($scope.input.belt.type == "none" ){
        if($config.getValidValue('beltCoating')){
            $location.path($config.getBaseUrl() + 'belt/coating');
        } else {
            $location.path($config.getBaseUrl() + 'belt/type');
        }
    }
    
    if($scope.input.belt.support == "slider") {
        $('#carryingRollers').addClass('unreachable');
    } else {
        $('#carryingRollers').removeClass('unreachable');
    }
    
    if($scope.input.belt.type == "none") {
        $('#beltDetails').addClass('unreachable');
    } else {
        $('#beltDetails').removeClass('unreachable');
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
    }
    
    //Setting default values for width of belt
    if($scope.input.belt.width == '' || typeof $scope.input.belt.width == undefined) {
        $scope.input.belt.width = $scope.input.conveyor.shellLength;
    }


    $scope.submit = function () {
        if($scope.form.$valid){
            if($scope.input.belt.type != "positiveModular") {
                $config.setInput($scope.input);
                $location.path($config.getBaseUrl() + 'belt/coating');
            } else {
                $config.setInput($scope.input);
                $location.path($config.getBaseUrl() + 'belt/support');
            }
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'belt/type');
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            if($scope.input.belt.type != "positiveModular" && $scope.input.belt.type != "positiveHomogeneous") {
                $config.setValid('beltCoating', true);
                $('#beltCoating').removeClass('unreachable');
            } else {
                $config.setValid('beltCoating', true);
                $config.setValid('beltSupport', true);
                $('#beltCoating').addClass('unreachable');
            }
        } else {
            $config.setValid('beltCoating', false);
            $config.setValid('beltSupport', false);
        }
    });
}]);