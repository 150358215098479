configApp.controller('CustomerLoginController', ['$scope', '$location', '$config', '$cookies','$http', '$route', '$rootScope',  function($scope, $location, $config, $cookies, $http, $route, $rootScope) {

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    //Vars
    $scope.selected;

    $scope.input = $config.getInput();

    $scope.lang = $config.getBaseUrl();


    $http.get($config.getServerPath() + "/api/v1/user/get").then(function(response) {
        if(response.status == 200 && response.data.status == 'loggedin') {
            $scope.input.customer = response.data.customer;
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'home/calculations');
        }
    });

    $scope.password = "";
    $scope.loading = false;
    $scope.msg = "";
    $scope.input.customer.data_privacy_confirmed = false;

    $scope.login = function() {
        if($scope.form.$valid) {
            var data = {
                email: $scope.input.customer.email,
                password: $scope.password,
                acceptDataPrivacy: $scope.input.customer && $scope.input.customer.data_privacy_confirmed
            };
            $scope.loading = true;
            $http.post($config.getServerPath() + "/api/v1/user/login", data).then(function(data) {
                $scope.msg = "";
                if(data.status == 200 && data.data.status == 'success') {
                    $scope.input.customer = data.data.customer;
                    $scope.input.customer.isLoggedIn = true;
                    $scope.input.systemOfMeasurement = data.data.customer.systemOfMeasurement;
                    $config.setInput($scope.input);

                    sessionStorage.setItem('token', data.data.token);

                    if(1 > $scope.indexHigh) {
                        $config.setIndex(1);
                    } else {
                        $config.setIndex($scope.indexHigh);
                    }
                    $location.path($config.getBaseUrl() + 'home/calculations');
                }
                else {
                    $scope.msg = data.data.msg;
                }
                $scope.loading = false;
            }, function(data) {
                if(data.status == 400 && data.data.status == 'accept-data-privacy') {
                  $scope.displayDataPrivacy = true;
                } else {
                  $scope.msg = "Server doesn't respond. Please check your internet connection.";
                }
                $scope.loading = false;
            });
        }
    };

    $scope.retrievePassword = function() {
        if($scope.form.$valid) {
            $scope.msg = "";
            $scope.loading = true;
            var data = {email: $scope.input.customer.email, return: window.location.href.split('#')[0]};
            $http.post($config.getServerPath() + "/api/v1/user/passwordremind", data).then(function(response) {
                if(response.status == 200 && response.data.status == 'loggedin') {
                    $location.path($config.getBaseUrl() + 'customer');
                } else if(response.status == 200) {
                    $scope.msg = response.data.message;
                } else {
                    $rootScope.$broadcast('overlay', {status : response.status});
                }
                $scope.loading = false;
            },
            function(response) {
                $rootScope.$broadcast('overlay', {status : response.status});
                $scope.loading = false;
            });
        }
    };

    $scope.setNewPw = function() {
        if($scope.form.$valid && $scope.newPW == $scope.newPWConfirm) {
            $scope.loading = true;
            $scope.msg= "";
            var data = {
                password : $scope.newPW,
                password_confirmation: $scope.newPWConfirm,
                token : $route.current.params.token,
                email : $route.current.params.email
            };
            $http.post($config.getServerPath() + "/api/v1/user/passwordreset", data).then(function(response) {

                if(response.status == 200 && response.data.status == 'loggedin') {
                    $location.path($config.getBaseUrl() + 'customer');
                } else if(response.status == 200 && response.data.status == 'success') {
                    $location.path($config.getBaseUrl() + 'customer');
                } else if(response.status == 200) {
                    $scope.msg = response.data.message;
                } else {
                    $rootScope.$broadcast('overlay', {status : response.status});
                }
                $scope.loading = false;
            },
            function(response) {
                $rootScope.$broadcast('overlay', {status : response.status});
                $scope.loading = false;
            });
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'customer');
    };

    $scope.backToLogin = function() {
        $location.path($config.getBaseUrl() + 'customer/login');
    };

}]);
