configApp.directive('myFormHelp', ['$document', '$compile', '$timeout', function($document, $compile, $timeout  ){
    function link(scope, elements, attrs){
        //Setting up focus and hover functions
        $document.ready(setuphelp());
        
        
        
    
        //Setting up initial helpboxes
        $('.infohover input, .infohover select').focus(inputevent).keypress(inputevent);
        //$('.description').html($compile($('.infohover:not(.ng-hide) .text').html())(scope));
        
        
        //Setting up initial box if radio select isnt pristine
        if($('.infohover.radio-input').size() != 0 && globalRadioSelect === null){
          arrowtop =  $('.infohover.radio-input').first().position().top + ($('.infohover.radio-input').first().find('.overlay').outerHeight() / 2) + $('.infohover.radio-input').first().find('span').outerHeight() - 20;

            $('.arrow').css('top', arrowtop );
        } else {
            //inital focus of help text
            if(globalRadioSelect != null){
                arrowtop = globalRadioSelect.position().top + (globalRadioSelect.find('.overlay').outerHeight() / 2) + globalRadioSelect.find('span').outerHeight() - 20;
                $('.arrow').css('top', arrowtop );
                $('.description').html($compile(globalRadioSelect.children('.text').html())(scope));
            }
        }
        
        
    };
    return{
        link: link
    };
}])
.directive('deleteConfirmation', ['$document', function($document){
    function link(scope, elements, attrs){
        $document.ready(confirmDeletion());
        $document.ready(cancelDeletion());
    };
    return{
        link: link
    };
}])
.directive('playTeaser', ['$document', function($document){
    function link(scope, elements, attrs){
        $document.ready(function() {
            if(!window.flexsliderPlaying){
                $('.config-teaser').flexslider("play");
                window.flexsliderPlaying = true;
            }
        });
    };
    return{
        link: link
    };
}])
.directive('nextTeaser', ['$document', function($document){
    function link(scope, elements, attrs){
        $document.ready(function() {
            if(!window.flexsliderPlaying) {
                $('.config-teaser').flexslider("next");
            }
        });
    };
    return{
        link: link
    };
}])
.directive('nextPlayTeaser', ['$document', function($document){
    function link(scope, elements, attrs){
        $document.ready(function() {
            $('.config-teaser').flexslider("next");
            $('.config-teaser').flexslider("play");
            window.flexsliderPlaying = true;
        });
    };
    return{
        link: link
    };
}])
.directive('alignHeight', ['$document', '$timeout', function($document, $timeout){
    function link(scope, elements, attrs){
        $timeout(function() {
            $document.ready(function () {
                function alignHeights() {
                    var elements = $('div.infohover');
                    var elementStructure = [[]];
                    var maxHeights = [$('div.infohover label>span').first().outerHeight()];
                    var cnt = 0;
                    var oldVal = $('div.infohover').first().offset().top;
                    elements.each(function (i, el) {
                        if ($(this).offset().top === oldVal) {
                            elementStructure[cnt].push($(this));
                            if ($(this).find('label>span').outerHeight() > maxHeights[cnt]) {
                                maxHeights[cnt] = $(this).find('label>span').outerHeight();
                            }
                        } else {
                            cnt++;
                            elementStructure[cnt] = [];
                            elementStructure[cnt].push($(this));
                            maxHeights[cnt] = $(this).find('label>span').outerHeight();
                        }
                        oldVal = $(this).offset().top;
                    });
                    var cntX = 0;
                    for (elems in elementStructure) {
                        for (elem in elementStructure[elems]) {
                            $(elementStructure[elems][elem]).find('label>span').outerHeight(maxHeights[cntX]);
                        }
                        cntX++;
                    }
                }

                alignHeights();
                $(window).resize(alignHeights);
            });
        });
    };
    return{
        link: link
    };
}]);
