configApp.controller('ConveyorTypeController', ['$scope', '$config', '$location', '$route', function($scope, $config, $location, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('conveyorType')) {
        $location.path($config.getBaseUrl() + 'application/details');
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;



    $scope.initialType = $scope.input.conveyor.type;
    var initialValid = {};
    angular.copy($scope.input.valid, initialValid);

    //Setting up help box correctly
    switch($scope.initialType) {
        case 'straightHorizontal':
            globalRadioSelect = $('#one').parent().parent();
            break;
        case 'straightInclinated':
            globalRadioSelect = $('#two').parent().parent();
            break;
        case 'lConveyor':
            globalRadioSelect = $('#three').parent().parent();
            break;
        case 'gooseneck':
            globalRadioSelect = $('#four').parent().parent();
            break;
        case 'zConveyor':
            globalRadioSelect = $('#five').parent().parent();
            break;
        default:
            globalRadioSelect = null;
            break;

    }

    $scope.submit = function () {
        if($scope.input.conveyor.type != "") {
            $location.path($config.getBaseUrl() + 'conveyor/details');
        }
    };

    $scope.back = function () {
        $location.path($config.getBaseUrl() + 'application/details');
    };

    function resetUpperLower () {
        switch($scope.input.conveyor.type) {
            case "straightHorizontal":
                $scope.input.conveyor.lengthUpperHorizontal = "";
                $scope.input.conveyor.inclinationLength = "";
                $scope.input.conveyor.heightDifference = "";
                $scope.input.conveyor.inclinationAngle = "";
                break;
            case "straightInclinated":
                $scope.input.conveyor.lengthUpperHorizontal = "";
                $scope.input.conveyor.lengthLowerHorizontal = "";
                break;
            case "lConveyor":
                $scope.input.conveyor.lengthUpperHorizontal = "";
                break;
            case "gooseneck":
                $scope.input.conveyor.lengthLowerHorizontal = "";
                break;
        }
    }

    $scope.$on("$destroy", function(){
        resetUpperLower();
        $config.setInput($scope.input);
    });

    $scope.changed = function() {
        $scope.input.calculation.inputsChanged = true;
        if($scope.initialType != $scope.input.conveyor.type){
            switch ($scope.initialType) {
                case 'straightHorizontal':
                    $config.setValid('conveyorLoad', false);
                    $config.setValid('conveyorAccumulation', false);
                    break;
                case 'straightInclinated':
                    if($scope.input.conveyor.type != 'straightHorizontal') {
                        $config.setValid('conveyorLoad', false);
                    }
                    break;
                case 'lConveyor':
                    $config.setValid('conveyorLoad', false);
                    break;
                case 'gooseneck':
                    $config.setValid('conveyorLoad', false);
                    break;
                case 'zConveyor':
                    if($scope.input.conveyor.type == 'straightHorizontal' || $scope.input.conveyor.type == 'straightInclinated') {
                        $config.setValid('conveyorLoad', false);
                    }
                    break;
            }
            if($scope.input.conveyor.type != ""){
                $config.setValid('conveyorDetails', true);
            } else{
                $config.setValid('conveyorDetails', false);
            }
        } else {
            angular.copy(initialValid, $scope.input.valid);
            $config.setInput($scope.input);
        }
    };

    //necessary to ensure continuing is possible with initial type from cookie
    //$config.setValid(3, true);
}]);
