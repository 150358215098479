configApp.controller('UserSettingsController', ['$scope', '$location', '$config', '$cookies','$http', '$rootScope',  function($scope, $location, $config, $cookies, $http, $rootScope) {

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    //$config.setIndex(0);

    //Vars
    $scope.selected;
    $scope.registered = false;

    $scope.input = $config.getInput();

    $scope.password = "";
    $scope.loading = false;
    $scope.msg = "";

    $scope.sent = false;
    
    $scope.favCountries = $config.getFavoriteCountries();
    $scope.countries = $config.getCountriesObject();

    $scope.save = function() {
        if($scope.form.$valid) {
            var data = {
                firstName: $scope.input.customer.firstName,
                lastName: $scope.input.customer.lastName,
                company : $scope.input.customer.company,
                lang : $scope.input.customer.lang,
                email : $scope.input.customer.email,
                country : $scope.input.customer.country,
                city : $scope.input.customer.city,
                street : $scope.input.customer.street,
                zip : $scope.input.customer.zip,
                phonenumber : $scope.input.customer.phonenumber,
                usertype : $scope.input.customer.usertype,
                interest : $scope.input.customer.interest,
                password: $scope.password,
                preferedSOU: $scope.input.customer.preferedSOU
            };
            $scope.loading = true;
            $http.put($config.getServerPath() + "/api/v1/user/update", data).then(function(response) {
                    if(response.data.status == 'success') {
                        $scope.input.customer = response.data.customer;
                        $scope.input.customer.isLoggedIn = true;
                        $scope.input.customer.preferedSOU = response.data.customer.preferedSOU;
                        $config.setInput($scope.input);
                    }
                    else {
                        $scope.msg = response.data.msg;
                    }
                    $scope.loading = false;
                },
                function(response) {
                    $rootScope.$broadcast('overlay', {status : response.status});
                    $scope.loading = false;
                });
        } else {
            $scope.sent = true;
        }
    };
}])
