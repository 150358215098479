configApp.controller('CustomerController', ['$scope', '$location', '$config', '$cookies', '$http', '$route', '$translate', function($scope, $location, $config, $cookies, $http, $route, $translate) {

    //Setting to null to ensure correct help box
    globalRadioSelect = null;


    //Vars
    $scope.selected;

    $scope.input = $config.getInput();

    $scope.indexHigh = $config.getIndex();

    $http.get($config.getServerPath() + "/api/v1/user/get").then(function(response) {
        if(response.status == 200 && response.data.status == 'loggedin') {
            $scope.input.customer = response.data.customer;
            $scope.input.customer.isLoggedIn = true;
            $scope.input.systemOfMeasurement = response.data.customer.systemOfMeasurement;
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'home/calculations');
        }
    });
    //Functions
    $scope.continue = function() {
        if($scope.selected === "login") {
            $location.path($config.getBaseUrl() + 'customer/login');
            return;
        }
        if($scope.selected === "register") {
            $config.resetInput();
            $config.resetCustomer();
            $scope.input = $config.getResetInput();
            $location.path($config.getBaseUrl() + 'customer/register');
            return;
        }
        if($scope.selected === "noLogin") {
            if($scope.input.application.case != '') {
                $location.path($config.getBaseUrl() + 'customer/nologin/resume');
            } else {
                $location.path($config.getBaseUrl() + 'customer/nologin');
            }
        }
    };

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$invalid) {
            $config.setIndex(0);
        }
        if($scope.form.$valid) {
            if(1 > $scope.indexHigh) {
                $config.setIndex(1);
            } else {
                $config.setIndex($scope.indexHigh);
            }
        }
    });
}]);