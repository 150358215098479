configApp.controller('BeltReturnController', ['$scope', '$location', '$config', '$route', function($scope, $location, $config, $route) {

    $scope.input = $config.getInput();

    //Checking if page request is legal
    if(!$config.validTo('beltCarrying')) {
        if($scope.input.belt.support == 'slider') {
            $location.path($config.getBaseUrl() + 'belt/support');
            return;
        }
        if($scope.input.belt.support == 'roller') {
            $location.path($config.getBaseUrl() + 'belt/carrying');
            return;
        }
    }

    if($scope.input.belt.type == "none" ){
        if($config.getValidValue('operationType')){
            $location.path($config.getBaseUrl() + 'operation/type');
        } else {
            $location.path($config.getBaseUrl() + 'belt/carrying');
        }
    }

    //Setting to null to ensure correct help box
    globalRadioSelect = null;

    
    if($scope.input.belt.support == "slider") {
        $('#carryingRollers').addClass('unreachable');
    } else {
        $('#carryingRollers').removeClass('unreachable');
    }
    
    if($scope.input.belt.type == "none") {
        $('#beltDetails').addClass('unreachable');
    } else {
        $('#beltDetails').removeClass('unreachable');
        if($scope.input.belt.type == "positiveModular" || $scope.input.belt.type == "positiveHomogeneous") {
            $('#beltCoating').addClass('unreachable');
        } else {
            $('#beltCoating').removeClass('unreachable');
        }
    }
    
    


    $scope.innerOuter = function() {
        $scope.input.calculation.inputsChanged = true;
        if($scope.input.systemOfMeasurement == 'metric') {
            if(parseFloat($scope.input.belt.returnRollers.innerDiameter) + 1 > parseFloat($scope.input.belt.returnRollers.outerDiameter)){
                $scope.form.returnOuter.$setValidity('innerGreaterThanOuter', false);
            } else {
                $scope.form.returnOuter.$setValidity('innerGreaterThanOuter', true);
            }
        } else {
            if(parseFloat($scope.input.belt.returnRollers.innerDiameter) + 0.04 > parseFloat($scope.input.belt.returnRollers.outerDiameter)){
                $scope.form.returnOuter.$setValidity('innerGreaterThanOuter', false);
            } else {
                $scope.form.returnOuter.$setValidity('innerGreaterThanOuter', true);
            }
        }
    };

    if($scope.input.belt.returnRollers.material == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.returnRollers.material = '7.85';
        } else {
            $scope.input.belt.returnRollers.material = '7.85';
        }
    }

    if($scope.input.belt.returnRollers.innerDiameter == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.returnRollers.innerDiameter = 57;
        } else {
            $scope.input.belt.returnRollers.innerDiameter = 2.24;
        }
    }

    if($scope.input.belt.returnRollers.outerDiameter == ''){
        if($scope.input.systemOfMeasurement == 'metric'){
            $scope.input.belt.returnRollers.outerDiameter = 60;
        } else {
            $scope.input.belt.returnRollers.outerDiameter = 2.36;
        }
    }

    if($scope.input.belt.returnRollers.quantity == '' || $scope.input.belt.returnRollers.quantity == null){
        $config.setInput($scope.input);
        $config.updateReturnRollersQuantity();
        $scope.input = $config.getInput();
    }


    $scope.submit = function () {
        if($scope.form.$valid ){
            $config.setInput($scope.input);
            $location.path($config.getBaseUrl() + 'operation/type');
        }
    };

    $scope.calcChanged = function() {
        $scope.input.calculation.inputsChanged = true;
    };

    $scope.goBack = function () {
        if($scope.input.belt.support == 'roller') {
            $location.path($config.getBaseUrl() + 'belt/carrying');
        } else {
            $location.path($config.getBaseUrl() + 'belt/support');
        }
    };

    $scope.$on("$destroy", function(){
        $config.setInput($scope.input);
    });

    $scope.$watch(function() { return $scope.form.$valid}, function() {
        if($scope.form.$valid) {
            $config.setValid('operationType', true);
        } else {
            $config.setValid('operationType', false);
        }
    });

}]);
